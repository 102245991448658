import React, { useEffect, useState } from 'react';
import AssetsLogo from 'src/images/assets_logo.svg';
import HalfVan from 'src/images/half_van.svg';
import PreboarderIcon from 'src/images/icon_preboarder.svg';
import CsgoIcon from 'src/images/icon_csgo.svg';
import SruIcon from 'src/images/icon_sru.svg';
import { useTranslation } from "react-i18next";
import Footer from 'src/components/Footer';
import { getUrlParameter } from "src/js/util";
import CookieHelper from "src/helpers/cookieHelper";
import Cookies from "src/constants/cookies";
import UserSelectionTile from './usertypes/UserSelectionTile';
import { UserType } from 'src/constants/usertype';
import { showPbModal, showCsgoModal, showSruModal } from 'src/constants/feature-gates';
import { View } from '@amzn/stencil-react-components/layout';
import TranslationKeys from 'src/constants/translation-keys';
import ScreenNames from 'src/constants/screen-names';

export default function LoginPickerScreen() {
    const { t } = useTranslation();
    const [login, setLogin] = useState('');
    const [errorIsShown, setErrorIsShown] = useState(false);
    const [dspModalIsShown, setDspModalIsShown] = useState(false);

    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
        showErrorPanelIfError();
    });

    function setLoginHintCookie() {
        CookieHelper.createCookie(Cookies.LOGIN_HINT, login, 30, '/', CookieHelper.resolveDomainStringForCookie());
    }

    function getProviderId() {
        const aaLoginUrlString: string = "{{AaEsspUrl}}";
        const providerId = getUrlParameter('providerId', aaLoginUrlString.split('?')[1]);
        return providerId;
    }

    function handleNavigateToDspScreen() {
        setDspModalIsShown(true);
        setErrorIsShown(false);
    }

    function showErrorPanelIfError() {
        const shouldShowErrorPanel: boolean = getUrlParameter("showError").length > 0;
        if (shouldShowErrorPanel) {
            setDspModalIsShown(false);
            setErrorIsShown(true);
        }
    }

    function handleGoBack() {
        setErrorIsShown(false);
        setDspModalIsShown(false);
        window.history.replaceState({}, document.title, "/");
    }

    return (
        <>
            <div className='background-top'></div>
            <div className='centered-content'>
                <div className="logo txt-cntr">
                    <img src={AssetsLogo} />
                </div>
                <div id="login-panel" className={"content-box username-content " + (errorIsShown ? 'hidden' : '')}>
                    <div id='subtle-picker' className={(dspModalIsShown ? 'hidden' : '')}>
                        <h2 id="get-started" className="top-h2"> {t('get_started_with_atoz')} </h2>
                        <p id="associate-login-text"> {t('associate_login_text')} </p>
                        <form id="aa-login-form" method="GET" action="{{AaEsspUrl}}">
                            <input type="hidden" name="loginType" value="AA" />
                            <input id="providerId-hidden-field" type="hidden" name="providerId" value={getProviderId()} />
                            <div className="form-group">
                                <label htmlFor="login">
                                    <p id="amazon-login-text" className="meta upcase"> {t('amazon_login')} </p>
                                </label>
                                <input id="associate-login-input" type="text" className="form-control"
                                    name="login" autoFocus maxLength={10} placeholder={t('associate_login_input_hint')}
                                    value={login} onChange={(e) => { setLogin(e.target.value) }} />
                            </div>
                            <div className="form-buttons">
                                <button id="log-in-btn" type="submit" className="btn btn-primary full-width" onClick={() => setLoginHintCookie()}>
                                    {t('log_in')}
                                </button>
                            </div>
                        </form>
                    </div>

                    <div id="lwa-speed-bump" className={(!dspModalIsShown ? 'hidden' : '')}>
                        <h2 id="dsp-login-text-lwa" className="top-h2">{t('dsp_login_text_lwa')}</h2>
                        <div className="dsp-instructions">
                            <img src={HalfVan} />
                            <p id="dsp-login-text-lwa-description">{t('dsp_login_text_lwa_description')}</p>
                        </div>
                        <form id="lwa-login-form" method="GET" action="{{DspEsspUrl}}">
                            <input type="hidden" name="loginType" value="DSP" />
                            <div className="form-buttons">
                                <button id="dsp-login-button" type="submit" className="btn btn-primary full-width">
                                    {t('dsp_login_button')}
                                </button>
                            </div>
                            <div className="form-buttons" style={{ marginTop: '10px' }}>
                                <button id="lwa-back-button" type="button" className="btn btn-link full-width" onClick={() => handleGoBack()}>
                                    {t('back_btn')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {(!errorIsShown && !dspModalIsShown) &&
                    <View padding={{ bottom: 10 }}>
                        <UserSelectionTile
                            userType={UserType.DA}
                            screenName={ScreenNames.LOGIN_PICKER}
                            iconSrc={HalfVan}
                            header={t('dsp_login_text_1')}
                            description={t('dsp_login_text_2')}
                            onClick={() => handleNavigateToDspScreen()}
                        />
                    </View>
                }
                {(!errorIsShown && !dspModalIsShown && showCsgoModal()) &&
                    <View padding={{ bottom: 10 }}>
                        <UserSelectionTile
                            userType={UserType.CSGO}
                            screenName={ScreenNames.LOGIN_PICKER}
                            header={TranslationKeys.UI.USERTYPES.CSGO_HEADER}
                            description={TranslationKeys.UI.USERTYPES.CSGO_DESCRIPTION}
                            iconSrc={CsgoIcon}
                            href={"{{CsgoEsspUrl}}"}
                        />
                    </View>
                }
                {(!errorIsShown && !dspModalIsShown && showSruModal()) &&
                    <View padding={{ bottom: 10 }}>
                        <UserSelectionTile
                            userType={UserType.SRU}
                            screenName={ScreenNames.LOGIN_PICKER}
                            header={TranslationKeys.UI.USERTYPES.SRU_HEADER}
                            description={TranslationKeys.UI.USERTYPES.SRU_DESCRIPTION}
                            iconSrc={SruIcon}
                            href={"{{SruEsspUrl}}"}
                        />
                    </View>
                }
                {(!errorIsShown && !dspModalIsShown && showPbModal()) &&
                    <View padding={{ bottom: 10 }}>
                        <UserSelectionTile
                            userType={UserType.PREBOARDER}
                            screenName={ScreenNames.LOGIN_PICKER}
                            header={TranslationKeys.UI.USERTYPES.PREBOARDER_HEADER}
                            description={TranslationKeys.UI.USERTYPES.PREBOARDER_DESCRIPTION}
                            iconSrc={PreboarderIcon}
                            href={"{{PbEsspUrl}}"}
                        />
                    </View>
                }
                <div id="error-panel" className={"error-main-box " + (!errorIsShown ? 'hidden' : '')}>
                    <div className="error-warning-header">!</div>
                    <div className="error-content">
                        <h2 id="title-error" className="top-h2"> {t('title_error')} </h2>
                        <p id="dsp-error-description">
                            {t('dsp_error_description_1')}
                            <br /> <br />
                            {t('dsp_error_description_2')}
                        </p>
                        <br />
                        <p id="dsp-error-new-account-title" className="light-text">
                            {t('dsp_error_new_account_title')}
                        </p>
                        <p id="dsp-error-new-account-description">
                            {t('dsp_error_new_account_description_1')}
                            <br /><br />
                            {t('dsp_error_new_account_description_2')}
                        </p>
                        <br />
                        <div className="form-buttons" style={{ marginTop: '10px' }}>
                            <button id="error-page-back-button" type="submit"
                                className="btn btn-primary full-width" onClick={() => handleGoBack()}>
                                {t('back_btn')}
                            </button>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}
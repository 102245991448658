import React from 'react';
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import { UserType } from 'src/constants/usertype';
import CsgoIcon from 'src/images/icon_csgo.svg';
import DaIcon from 'src/images/icon_da.svg';
import DspIcon from 'src/images/icon_dsp.svg';
import SruIcon from 'src/images/icon_sru.svg';
import PreboarderIcon from 'src/images/icon_preboarder.svg';
import UserSelectionTile from './UserSelectionTile';
import { showPbModal, showCsgoModal, showSruModal, showDspaModal } from 'src/constants/feature-gates';
import TranslationKeys from 'src/constants/translation-keys';
import { redirect } from 'react-router';
import ScreenNames from 'src/constants/screen-names';

const UserSelectionTileList = () => {
    return (
        <Container>
            <Col>
                <ul className='ul-no-bullets'>
                    <li>
                        <View padding={{ bottom: 10 }}>
                            <UserSelectionTile
                                userType={UserType.DA}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={TranslationKeys.UI.USERTYPES.DA_HEADER}
                                description={TranslationKeys.UI.USERTYPES.DA_DESCRIPTION}
                                iconSrc={DaIcon}
                                onClick={() => redirect("{{DaEsspUrl}}")}
                                href={"{{DaEsspUrl}}"} />
                        </View>
                    </li>
                {showDspaModal() &&
                    <li>
                        <View padding={{ bottom: 10 }}>
                            <UserSelectionTile
                                userType={UserType.DSP}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={TranslationKeys.UI.USERTYPES.DSP_HEADER}
                                description={TranslationKeys.UI.USERTYPES.DSP_DESCRIPTION}
                                iconSrc={DspIcon}
                                onClick={() => redirect("{{DspaEsspUrl}}")}
                                href={"{{DspaEsspUrl}}"}
                                hidden={!showDspaModal()} 
                            />
                        </View>
                    </li>
                }
                {showCsgoModal() &&
                    <li>
                        <View padding={{ bottom: 10 }}>
                            <UserSelectionTile
                                userType={UserType.CSGO}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={TranslationKeys.UI.USERTYPES.CSGO_HEADER}
                                description={TranslationKeys.UI.USERTYPES.CSGO_DESCRIPTION}
                                iconSrc={CsgoIcon}
                                onClick={() => redirect("/csgo-region")}
                                href={"/csgo-region"}
                                hidden={!showCsgoModal} 
                            />
                        </View>
                    </li>
                }
                {showSruModal() &&
                    <li>
                        <View padding={{ bottom: 10 }}>
                            <UserSelectionTile
                                userType={UserType.SRU}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={TranslationKeys.UI.USERTYPES.SRU_HEADER}
                                description={TranslationKeys.UI.USERTYPES.SRU_DESCRIPTION}
                                iconSrc={SruIcon}
                                href={"{{SruEsspUrl}}"}
                                onClick={() => redirect("{{SruEsspUrl}}")}
                                hidden={!showSruModal}
                            />
                        </View>
                    </li>
                }
                {showPbModal() &&
                    <li>
                        <View padding={{ bottom: 10 }}>
                            <UserSelectionTile
                                userType={UserType.PREBOARDER}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={TranslationKeys.UI.USERTYPES.PREBOARDER_HEADER}
                                description={TranslationKeys.UI.USERTYPES.PREBOARDER_DESCRIPTION}
                                iconSrc={PreboarderIcon}
                                onClick={() => redirect("{{PbEsspUrl}}")}
                                href={"{{PbEsspUrl}}"}
                                hidden={!showPbModal} 
                            />
                        </View>
                    </li>
                }
                </ul>
            </Col>
        </Container>
    )
}

export default UserSelectionTileList;
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'src/styles/app.scss';
import { KatSpinner } from '@amzn/katal-react'

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import './js/localization';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
    <React.StrictMode>
        <StencilProvider>
            <AppInitWrapper>
                <Suspense fallback={<KatSpinner size='large' />}>
                    <App />
                </Suspense>
            </AppInitWrapper>
        </StencilProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
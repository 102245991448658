import React from 'react';
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import { useTranslation } from "react-i18next";
import RightChevron from 'src/images/icon_rightchevron.svg';
import LeftChevron from 'src/images/icon_leftchevron.svg';
import SelectionTile from '../common/SelectionTile';
import TranslationKeys from 'src/constants/translation-keys';
import { redirect } from 'react-router';
import ScreenNames from 'src/constants/screen-names';

const CSGORegionSelectionTileList = () => {
    const { t, i18n } = useTranslation();
    const rtl = i18n.dir() === 'rtl';

    return (
        <Container data-testid='csgo-selection-regions'>
            <Col>
                <View padding={{ bottom: 10 }}>
                    <SelectionTile
                        dataTestId='csgo-selection-north-america'
                        name="NA"
                        screenName={ScreenNames.CSGO_REGION_SELECTION}
                        onClick={() => redirect("{{CsgoEsspUrlNA}}")}
                        href={"{{CsgoEsspUrlNA}}"}>
                        <div><b>{t(TranslationKeys.UI.CSGO.CSGO_REGION_NORTH_AMERICA)}</b></div>
                        <img className="chevron" src={rtl ? LeftChevron : RightChevron} alt="" aria-hidden={true} />
                    </SelectionTile>
                </View>
                <View padding={{ bottom: 10 }}>
                    <SelectionTile
                        dataTestId='csgo-selection-europe'
                        name="EU"
                        screenName={ScreenNames.CSGO_REGION_SELECTION}
                        onClick={() => redirect("{{CsgoEsspUrlEU}}")}
                        href={"{{CsgoEsspUrlEU}}"}>
                        <div><b>{t(TranslationKeys.UI.CSGO.CSGO_REGION_EUROPE)}</b></div>
                        <img className="chevron" src={rtl ? LeftChevron : RightChevron} alt="" aria-hidden={true} />
                    </SelectionTile>
                </View>
                <View padding={{ bottom: 10 }}>
                    <SelectionTile
                        dataTestId='csgo-selection-apac'
                        name="FE"
                        screenName={ScreenNames.CSGO_REGION_SELECTION}
                        onClick={() => redirect("{{CsgoEsspUrlFE}}")}
                        href={"{{CsgoEsspUrlFE}}"}>
                        <div><b>{t(TranslationKeys.UI.CSGO.CSGO_REGION_APAC)}</b></div>
                        <img className="chevron" src={rtl ? LeftChevron : RightChevron} alt="" aria-hidden={true} />
                    </SelectionTile>
                </View>
            </Col>
        </Container>
    )
}

export default CSGORegionSelectionTileList;
import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import awsCreds from './aws-creds-slice';
import errorContext from './error-context-slice';
import welcomeInfo from './welcome-info-slice';
import attribute from './attribute-slice';
import { inviteApi } from '../services/invite';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

const rootReducer = combineReducers({
    awsCreds,
    errorContext,
    welcomeInfo,
    attribute,
    [inviteApi.reducerPath]: inviteApi.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: sessionStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(inviteApi.middleware),
        preloadedState,
    });
}

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(inviteApi.middleware),
});
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export default store;
